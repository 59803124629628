import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { Product } from '../interfaces/Product';
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private http: HttpClient) { }

  getProducts(): Observable<Product[]>{
    
    return this.http.get<Product[]>(`${environment.BASE_URL}/product`);
  }

  getProduct(id: string): Observable<Product>{
    return this.http.get<Product>(`${environment.BASE_URL}/product/${id}`);
  }

  createProduct(product: Product): Observable<Product> {
    
    return this.http.post<Product>(`${environment.BASE_URL}/product/create`, product);
     
  }
  

  deleteProduct(id: string): Observable<Product> {
    console.log(id);
    return this.http.delete<Product>(`${environment.BASE_URL}/product/delete?productID=${id}`);
  }

  updateProduct(id: string, product: Product): Observable<Product> {
   console.log("Hola" + product); 
    return this.http.put<Product>(`${environment.BASE_URL}/product/update?productID=${id}`, product);
  }

}
